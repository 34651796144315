/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Jaunev0.6-GrandeLight";

  src: url("./fonts/Jaunev06-GrandeLightTRIAL.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Jaunev06-GrandeLightTRIAL.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Jaunev0.6-GrandeBold";

  src: url("./fonts/Jaunev06-GrandeBoldTRIAL.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Jaunev06-GrandeBoldTRIAL.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}